import { GET, POST } from "./common/http";
import { BASE_URL } from "./common/const";

export const GetInvestigationSampleCollectedFromList = (params: any) =>
    GET(`${BASE_URL}/api/InvestigationSampleCollectedFrom/GetList?` + params, null);
  
export const GetInvestigationSampleCollectedFromDetail = (params: any) =>
  GET(`${BASE_URL}/api/InvestigationSampleCollectedFrom/GetDetail?` + params, null);

export const CreateInvestigationSampleCollectedFrom = (data: any) =>
  POST(`${BASE_URL}/api/InvestigationSampleCollectedFrom/Create`, data);

export const UpdateInvestigationSampleCollectedFrom = (data: any) =>
  POST(`${BASE_URL}/api/InvestigationSampleCollectedFrom/Update`, data);    

export const DeleteInvestigationSampleCollectedFrom = (data: any) =>
  POST(`${BASE_URL}/api/InvestigationSampleCollectedFrom/Delete`, data);