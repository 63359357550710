import React, { useContext } from "react";
import { Box } from "@mui/material";
import MissingConsumer from "./MissingConsumer";
import { PreferencesContext } from "../../PreferenceContext";
import { useLocation } from "react-router-dom";

export default function ConsumerNotFound(props: any) {
  const { userContext } = useContext(PreferencesContext);
  let location = useLocation();

  if (userContext.consumerId > 0 || ['/consumers','/consumers/consumer-save'].includes(location.pathname) ) {
    return <>{props.children}</>;
  } else {
    return (
      <Box className="main-container">
        <MissingConsumer consumerId={userContext.consumerId} />
      </Box>
    );
  }
}
