// IMPORT REACT
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

// IMPORT UI COMPONENTS
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";

// IMPORT SERVICE
import { Context } from "../../services/models/Context";
import { PreferencesContext } from "../../PreferenceContext";

export default function UnauthorizeDialog(props: { isOpen: boolean }) {
  // HOOKS AND CONTEXT
  const { updateUserContext } = useContext(PreferencesContext);
  let navigate = useNavigate();


  const handlelogin = () => {
    let context = new Context();
    updateUserContext(context);
    navigate("/");
  };

  return (
    <div>
      <Dialog open={props.isOpen}   fullWidth>
        <DialogContent style={{ minHeight: 200, paddingTop: 50 }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <img src="/session.svg" alt="session expired img" />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <h2 style={{ paddingTop: 20 }}>Your session has expired</h2>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Typography >
                Please sign in again to start your everyday work.
              </Typography>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", paddingBottom: 8 }}>
          <Button
            color="primary"
            variant="contained"
            className="button-primary"
            onClick={handlelogin}
          >
            Sign In
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
