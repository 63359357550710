import { GET, POST } from "./common/http";
import { BASE_URL } from "./common/const";

export const GetBranchDetail = (params: any) =>
  GET(`${BASE_URL}/api/Branch/GetDetail?` + params, null);

export const GetBranchList = (params: any) =>
  GET(`${BASE_URL}/api/Branch/GetList?` + params, null);

export const CreateBranch = (data: any) =>
  POST(`${BASE_URL}/api/Branch/Create`, data);

export const UpdateBranch = (data: any) =>
  POST(`${BASE_URL}/api/Branch/Update`, data);    

export const DeleteBranch = (data: any) =>
  POST(`${BASE_URL}/api/Branch/Delete`, data);