let curMode = window.config.mode;
let apiUrl: any;
if (curMode != undefined && curMode.toLowerCase() === "local") {
    apiUrl = process.env.REACT_APP_API_URL;
} else if (curMode != undefined && curMode.toLowerCase() === "staging") {
    apiUrl = process.env.REACT_APP_STAGING_API_URL;
} else if (curMode != undefined && curMode.toLowerCase() === "production") {
    apiUrl = process.env.REACT_APP_PROD_API_URL;
}

export const BASE_URL = apiUrl;
export const PAGE_SIZE = +process.env.REACT_APP_PRIMARY_PAGE_SIZE!;
export const PAGE_SIZES = +process.env.REACT_APP_SECONDARY_PAGE_SIZE!;
export const SITE_TITLE = process.env.REACT_APP_SITE_TITLE;
export const ANDROID_APP_VERSION =  process.env.REACT_APP_ANDROID_APP_VERSION;
export const IOS_APP_VERSION =  process.env.REACT_APP_IOS_APP_VERSION;
export const MAINTENANCE_URL = "../../../maintenance.html";

/*

export const BASE_URL = process.env.REACT_APP_API_URL;
export const UI_APP_URL = process.env.REACT_APP_WEB_URL;
export const DEVELOPMENT_MODE = process.env.REACT_APP_ENV;
export const PAGE_SIZE = +process.env.REACT_APP_PRIMARY_PAGE_SIZE!;
export const PAGE_SIZES = +process.env.REACT_APP_SECONDARY_PAGE_SIZE!;
export const SITE_TITLE = process.env.REACT_APP_SITE_TITLE;
export const MAINTENANCE_URL = "../../../maintenance.html";
export const ANDROID_APP_VERSION =  process.env.REACT_APP_ANDROID_APP_VERSION;
export const IOS_APP_VERSION =  process.env.REACT_APP_IOS_APP_VERSION;

*/