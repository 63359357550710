// IMPORT REACT
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// IMPORT CSS
import "../../../theme/style.scss";

// IMPORT UI COMPONENTS
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  Popover,
  Pagination,
  Stack,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import { AlertType } from "../../../services/models/AlertType";
import SkeletonWrapper from "../../../components/Skeleton/SkeletonWrapper";
import CustomBreadcrumbs from "../../../components/BreadcrumbsComponent/CustomBreadcrumbs";
import { PAGE_SIZE } from "../../../services/common/const";
import { PreferencesContext } from "../../../PreferenceContext";
import { EncryptDecryptService } from "../../../services/common/EncryptionService";

// IMPORT SERVICE
import { DeleteInvestigationSampleCollectedFrom, GetInvestigationSampleCollectedFromList } from "../../../services/InvestigationSampleCollectedFromService";


// SCHEMA
interface Data {
  investigationSampleCollectedFromId: number;
  consumerId: number;
  orgId: number;
  branchId: number;
  code: string;
  investigationSampleCollectedFromName: string;
  isDeleted: boolean;
}

type Order = "asc" | "desc" | "";

// SCHEMA FOR TABLE
interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: String;
  rowCount: number;
}

// SCHEMA FOR PARAMETER
interface Parameter {
  investigationSampleCollectedFromId: string;
  pageIndex: number;
  pageSize: number;
  sortColumn: string;
  sortDirection: string;
  searchValue: string;
}

// DEFAULT PARAMS -- ADD HERE IF YOU NEED NEW PARAMETER
const parameters: Parameter = {
  investigationSampleCollectedFromId: "",
  pageIndex: 1,
  pageSize: PAGE_SIZE,
  sortColumn: "",
  sortDirection: "",
  searchValue: "",
};

// SCHEMA FOR RESULTDATA
interface ResultData {
  investigationSampleCollectedFromColl: Array<any>;
  totalCount: number;
}

// DEFAULT VALUES FOR RESULT DATA
const initResultData: ResultData = {
  investigationSampleCollectedFromColl: [],
  totalCount: 0,
};

// TABLE ROWS FOR SKELETON
const skeletonRowCount = 5;

export default function InvestigationSampleCollectedFromList() {
  // HOOKS AND CONTEXT
  const navigate = useNavigate();
  const edService = new EncryptDecryptService();
  const { userContext, updateUserContext } = useContext(PreferencesContext);

  // BREAK POINTS
  // const xs = useMediaQuery(theme.breakpoints.up("xs")); //-- FOR REF PLEASE ONLY TAKE THEME BREAK POINTS FOR MEDIA QUERY
  // const sm = useMediaQuery(theme.breakpoints.up("sm"));
  // const md = useMediaQuery(theme.breakpoints.up("md"));
  // const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const [param, setParam] = useState(parameters);
  const [order, setOrder] = React.useState<Order>(""); // DON"T USE  DIFFERENT STATE FOR EVERY PARAMETER USE PARAMETER STATE INSTAND
  const [orderBy, setOrderBy] = React.useState<keyof Data>("none");
  const [rowsPerPage, setRowsPerPage] = React.useState(PAGE_SIZE);
  const [page, setPage] = React.useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [value, setValue] = useState("");
  const [counData, setCounData] = useState(0);
  const [currPage, setCurrPage] = React.useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pageStartIndex, setPageStartIndex] = useState(1);
  const [pageEndIndex, setPageEndIndex] = useState(1);

  // STATE FOR STORAGE
  const [openDeleteDilog, setOpenDeleteDilog] = React.useState(false);
  const [resultData, setResultData] = useState<ResultData>(initResultData);
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [investigationSampleCollectedFromId, setInvestigationSampleCollectedFromId] = useState(0);
  const [anchorEl, setAnchorEl3] = React.useState<HTMLButtonElement | null>(
    null
  );

  // STATES -- FOR ERROR HANDLING AND LOADING
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showSkeleton, setShowSkeleton] = useState<boolean>(true);

  // HANDLE POPUP FOR EDIT AND DELETE
  const handleOpenMore = (
    event: React.MouseEvent<HTMLButtonElement>,
    investigationSampleCollectedFromId: any
  ) => {
    setAnchorEl3(event.currentTarget);
    setInvestigationSampleCollectedFromId(investigationSampleCollectedFromId);
  };

  // CLOSE POPUP
  const handleCloseMore = () => {
    setAnchorEl3(null);
  };

  // TABLE HEADER
  function EnhancedTableHead(props: EnhancedTableProps) {
    return (
      <TableHead className="table-header-color">
        <TableRow>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "80%",
            }}
            align="left">
            Sample Collected From
          </TableCell>
          <TableCell
            sx={{
              display: {
                xs: "table-cell",
                sm: "table-cell",
                md: "table-cell",
                lg: "table-cell",
              },
              fontWeight: "bold",
              width: "20%",
            }}
            align="left"></TableCell>
        </TableRow>
      </TableHead>
    );
  }

  // SORTING TABLE
  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array?.map(
      (el, index) => [el, index] as [T, number]
    );
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  // DESCENDING COMPERISION
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  // GET COMPERISION
  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // GET LIST OF CONSUMER
  const getInvestigationSampleCollectedFromList = async () => {
    try {
      let params = new URLSearchParams();
      params.append("consumerId", userContext.consumerId?.toString());
      params.append("organizationId", userContext.organizationId?.toString());
      params.append("branchId", userContext.branchId?.toString());
      params.append("pageIndex", param.pageIndex?.toString());
      params.append("pageSize", param.pageSize?.toString());
      params.append("sortColumn", param.sortColumn);
      params.append("sortDirection", param.sortDirection);
      params.append("searchValue", param.searchValue);
      const result: any = await GetInvestigationSampleCollectedFromList(params);
      if (result?.data?.data?.investigationSampleCollectedFromColl) {
        const resultData: ResultData = result?.data?.data;
        let investigationSampleCollectedFromColl = resultData?.investigationSampleCollectedFromColl || [];
        let dataCount = resultData?.totalCount || 0;
        let showDataCount = Math.ceil(dataCount / param.pageSize);
        setCounData(showDataCount);
        setResultData(resultData);
        let startIndex = (pageIndex - 1) * param.pageSize + 1;
        let endIndex =
          (pageIndex - 1) * param.pageSize + (investigationSampleCollectedFromColl?.length || 0);
        setPageStartIndex(startIndex);
        setPageEndIndex(endIndex);
        setTotalCount(dataCount);
      } else {
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log("err:", e);
    }
  };

  // ON CHANGE PAGE
  const handleChangePageNew = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    if (currPage !== value) {
      setCurrPage(value);
      setPageIndex(value);
      setParam({ ...param, pageIndex: value });
    }
  };

  // ON SERCH
  function searchValue(val: any) {
    if (val === "") {
      setValue("");
    }
    setPageIndex(1);
    setParam({ ...param, searchValue: val.toString(), pageIndex: 1 });
  }

  // HANDLE UPDATE
  const handleUpdate = (investigationSampleCollectedFromId: any) => {
    let encryptInvestigationSampleCollectedFromId: any = edService.encrypt(investigationSampleCollectedFromId);
    navigate(
      {
        pathname: "investigation-sample-collected-from-save",
        search: `?q=${encodeURIComponent(encryptInvestigationSampleCollectedFromId)}`,
      },
      {
        state: {
          title: " Edit",
          button: "Update",
          investigationSampleCollectedFromId: investigationSampleCollectedFromId,
        },
      }
    );
  };

  // HANDLE DELETE WARNING POPUP
  const handleDeleteDilog = (value) => {
    setOpenDeleteDilog(value);
  };

  // HANDLE DELETE
  async function deleteRow(investigationSampleCollectedFromIds: any) {
    try {
      setAnchorEl3(null);
      handleDeleteDilog(false);
      let DeleteData = {
        investigationSampleCollectedFromIds: investigationSampleCollectedFromIds?.toString(),
        consumerId: userContext?.consumerId?.toString(),
        organizationId: userContext?.organizationId?.toString(),
        branchId: userContext?.branchId?.toString(),
        isDeleted: true,
        dataManagedBy: userContext?.loginDetail?.userId,
      };
      const result: any = await DeleteInvestigationSampleCollectedFrom(DeleteData);
      if (result) {
        await getInvestigationSampleCollectedFromList();
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: result.message,
          alertType: AlertType.Success,
        });
      } else {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: result.message,
          alertType: AlertType.Error,
        });
      }
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }
  }

  // HANDLE CHECK ROW
  const handleCheckSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = resultData?.investigationSampleCollectedFromColl?.map(
        (n: any) => n?.investigationSampleCollectedFromId || 0
      );
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // USEEFFECTS
  useEffect(() => {
    setIsLoading(true);
    getInvestigationSampleCollectedFromList();
  }, [param]);

  // HANDLE DELAY FOR SKELETON
  useEffect(() => {
    if (!isLoading) {
      const timeout = setTimeout(() => setShowSkeleton(false), 50); // Delay unmounting the Skeleton
      return () => clearTimeout(timeout);
    } else {
      setShowSkeleton(true);
    }
  }, [isLoading]);

  return (
    <>
      <Box>
        <Box className="main-container">
          <Grid container className="page-header">
            <Grid item xs={6}>
              <Box
                sx={{ display: "flex", height: "100%", alignItems: "center" }}>
                <CustomBreadcrumbs />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className="page-header-icon">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    handleUpdate(0);
                  }}
                  size="medium"
                  startIcon={<AddIcon />}>
                  Add
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid container className="list-container">
            <Grid item xs={8} sm={8} md={8} lg={10} sx={{ gap: 2 }}>
              <SkeletonWrapper
                isLoading={showSkeleton}
                className="skeleton-search-box">
                <TextField
                  id="searchBox"
                  placeholder="Search..."
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      searchValue(value);
                    }
                  }}
                  type="text"
                  variant="outlined"
                  size="small"
                  onChange={(e) => setValue(e.target.value)}
                  value={value}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          onClick={() => {
                            searchValue(value);
                          }}>
                          {" "}
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: value && (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          searchValue("");
                        }}>
                        <ClearIcon />
                      </IconButton>
                    ),
                  }}
                />
              </SkeletonWrapper>
            </Grid>
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={2}
              className="search-bar pdf-icon">
              <SkeletonWrapper
                isLoading={showSkeleton}
                className="skeleton-search-pdf-icon">
                <img src="./xls.png" alt="" className="pdf-icon-hover" />
              </SkeletonWrapper>
              <SkeletonWrapper
                isLoading={showSkeleton}
                className="skeleton-search-pdf-icon">
                <img src="./pdf.png" alt="" className="pdf-icon-hover" />
              </SkeletonWrapper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
              <TableContainer>
                <Table>
                  <EnhancedTableHead
                    numSelected={resultData?.investigationSampleCollectedFromColl?.length || 0}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleCheckSelectAll}
                    //onRequestSort={handleRequestSort}
                    rowCount={resultData?.investigationSampleCollectedFromColl?.length}
                  />
                  <TableBody>
                    {showSkeleton ? (
                      Array(skeletonRowCount)
                        ?.fill(0)
                        ?.map((skeletonRow, index) => {
                          return (
                            <TableRow key={"skeleton-row" + index?.toString()}>
                              <TableCell colSpan={8}>
                                <SkeletonWrapper
                                  isLoading={showSkeleton}
                                  className="skeleton-input">
                                  <span></span>
                                </SkeletonWrapper>
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <>
                        {stableSort(
                          resultData?.investigationSampleCollectedFromColl,
                          getComparator(order, orderBy)
                        )
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((item: any, index: any) => {
                            return (
                              <TableRow key={item?.investigationSampleCollectedFromId?.toString()}>
                                <TableCell>
                                  <Link
                                    className="text-link"
                                    to={{
                                      pathname: "investigation-sample-collected-from-save",
                                      search: `?q=${encodeURIComponent(
                                        edService.encrypt(
                                          item?.investigationSampleCollectedFromId || 0
                                        ) || 0
                                      )}`,
                                    }}>
                                      {(item.code != "") ? item.code + " - " + item.investigationSampleCollectedFromName : item.investigationSampleCollectedFromName}
                                  </Link>
                                </TableCell>
                                <TableCell align="right">
                                  <Tooltip title="More" placement="top">
                                    <IconButton
                                      onClick={(e: any) => {
                                        handleOpenMore(
                                          e,
                                          item?.investigationSampleCollectedFromId || 0
                                        );
                                      }}>
                                      <MoreVertRoundedIcon />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {resultData?.investigationSampleCollectedFromColl?.length <= 0 && (
                          <TableRow>
                            <TableCell align="center" colSpan={8}>
                              No record(s) found
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} paddingTop={1}>
              {resultData?.investigationSampleCollectedFromColl?.length > 0 && (
                <Box className="pagination">
                  <Box className="pagination-label">
                    <SkeletonWrapper
                      isLoading={showSkeleton}
                      className="skeleton-pagination-label">
                      {pageStartIndex} - {pageEndIndex} of {totalCount}
                    </SkeletonWrapper>
                  </Box>
                  <Box className="pagination-stack">
                    <SkeletonWrapper
                      isLoading={showSkeleton}
                      className="skeleton-pagination-stack">
                      <Stack component="div">
                        <Pagination
                          color="primary"
                          count={counData} // NUMBER OF PAGES
                          page={currPage} // CURRENT PAGE
                          onChange={handleChangePageNew} //HANDLE PAGE CHANGE
                        />
                      </Stack>
                    </SkeletonWrapper>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Popover
        elevation={1}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseMore}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <List>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                handleUpdate(investigationSampleCollectedFromId);
              }}>
              <ListItemText primary="Edit" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              className="delete-container"
              onClick={() => {
                handleDeleteDilog(true);
              }}>
              Delete
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>

      <Dialog
        open={openDeleteDilog}
        onClose={() => {
          handleDeleteDilog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this <strong>Investigation Sample Collected From?</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              deleteRow(investigationSampleCollectedFromId);
            }}
            className="button-delete">
            Yes
          </Button>
          <Button
            onClick={(e) => {
              handleDeleteDilog(false);
            }}
            autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
