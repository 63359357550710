
import {
    Tooltip,
    TooltipProps,
    tooltipClasses,
  } from "@mui/material";
  import { styled } from "@mui/material/styles";
import React from "react";


export const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "rgb(65, 77, 85)",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "rgb(65, 77, 85)",
    },
  }));