import { GET, POST } from "./common/http";
import { BASE_URL } from "./common/const";


export const GetUserDetailById = (params: any) =>
  GET(`${BASE_URL}/api/User/GetDetailById?` + params, null);

export const GetUserDetail = (params: any) =>
  GET(`${BASE_URL}/api/User/GetDetail?` + params, null);

export const GetUserList = (params: any) =>
  GET(`${BASE_URL}/api/User/GetList?` + params, null);

export const CreateUser = (data: any) =>
  POST(`${BASE_URL}/api/User/Create`, data);

export const GetSaveUserOrgBranchSelection = (params: any) =>
  GET(`${BASE_URL}/api/User/GetSaveUserOrgBranchSelection?` + params, null);

export const DeleteUser = (data: any) =>
  POST(`${BASE_URL}/api/User/Delete`, data);

export const UpdateUser = (data: any) =>
  POST(`${BASE_URL}/api/User/Update`, data);

export const ChangePassword = (data: any) =>
  POST(`${BASE_URL}/api/User/ForgotPassword`, data);
// 
export const SendInvite = (data: any) =>
  POST(`${BASE_URL}/api/User/SendInvite`, data);

export const ReInvite = (data :any) =>{
  POST(`${BASE_URL}/api/User/ReInvite`,data);
}
export const UpdateDefaultInvoice = (data: any) =>
  POST(`${BASE_URL}/api/User/UpdateDefaultInvoice`, data);

