import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function MissingConsumer(props: { consumerId: number }) {
  let navigate = useNavigate();

  const handleGoToConsumer = (e: any) => {
    e.preventDefault();
    navigate({
      pathname: "/consumers",
    });
  };

  return (
    <>
      {props.consumerId === 0 ? (
        <Grid className="paper group-container">
          <Paper
            elevation={4}
            className="paper-container"
            style={{
              textAlign: "center",
              minHeight: "calc(100vh - 170px)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3 className="page-title">Consumer is missing in selection!</h3>

            <Button
              color="primary"
              variant="contained"
              onClick={handleGoToConsumer}
              size="medium"
            >
              Go To Consumers
            </Button>
          </Paper>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}
