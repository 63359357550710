import { Context } from "../models/Context";
import { EncryptDecryptService } from "./EncryptionService";
// import { Login, RefreshToken } from "../AuthService";

const STORAGE_KEY = "hpv_1s";
const VERSION_KEY = "htpv";

function isTokenExpired(loginExpiryTime: string) {
  if (loginExpiryTime === "") {
    return true;
  }
  else {
    let expiryTime: Date = new Date(loginExpiryTime);
    let nowDate: Date = new Date(new Date().toUTCString());
    let pendingSeconds: number = (+expiryTime - +nowDate) / 1000;
    if (pendingSeconds < 10) {
      return true;
    }
    else {
      return false;
    }
  }

}

async function storeDataToStorage(credentials: Context) {
  try {
    if (!(credentials && credentials?.loginDetail?.token)) {
      credentials = new Context();
    }

    //remove existing
    localStorage.removeItem(STORAGE_KEY);

    const edService = new EncryptDecryptService();

    //  // Save to local storage enc
    const A = edService.encrypt(JSON.stringify(credentials))!!.toString()

    //not enc
    // const A = JSON.stringify(credentials)!!.toString();
    //add new
    localStorage.setItem(STORAGE_KEY, A);

  } catch (error) {
    // Error saving data
    console.log(error);
  }
}

const readDataFromStorage = () => {
  try {
    let credentials = localStorage.getItem(STORAGE_KEY);
    let storage_value: any = null;
    // NO ENCRYPTION
    // storage_value = credentials;

    // WITH ENCRYPTION
    const edService = new EncryptDecryptService();
    const d = edService.decrypt(credentials)

    // let storage_value;
    if (d != null) {
      storage_value = d;
    }
    let context: Context = (storage_value) ? JSON.parse(storage_value) : null;
    //let cred = await getVerifiedStorageInfo(context)
    //if (credentials != null && cred != null) {
    if (context != null) {
      if ( context?.loginDetail?.tokenExpiryTime !== "" && context?.loginDetail?.token !=="" ) {
        if (isTokenExpired(context?.loginDetail?.tokenExpiryTime)) {
          context.loginDetail.isAuthorized = false;
          setStorage(context);
        }
      }
      return context
    } else {
      return new Context();
    }
  } catch (error) {
    console.error(error)
  }
  return new Context();
}


export const getStorage = () => {
  return readDataFromStorage();
}

export function setStorage(credentials: Context) {
  return storeDataToStorage(credentials);
}

export const getVersion = () => {
  return localStorage.getItem(VERSION_KEY);;
}

export function setVersion(version: string) {
  return localStorage.setItem(VERSION_KEY, version);;
}