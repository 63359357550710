// IMPORT REACT
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// IMPORT PACKAGES
import * as Yup from "yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SkeletonWrapper from "../../../components/Skeleton/SkeletonWrapper";
import { CustomTextField } from "../../../components/CustomFields/TextField";
// IMPORT CSS
import "../../../theme/style.scss";
import "react-phone-number-input/style.css";

// IMPORT UI COMPONENTS
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingButton from "@mui/lab/LoadingButton";

// IMPORT SERVICE

import PhoneInput from "react-phone-number-input";
import { PreferencesContext } from "../../../PreferenceContext";
import { EncryptDecryptService } from "../../../services/common/EncryptionService";
import {
  CreateInvestigationSampleCollectedFrom,
  GetInvestigationSampleCollectedFromDetail,
  UpdateInvestigationSampleCollectedFrom,
} from "../../../services/InvestigationSampleCollectedFromService";
import { AlertType } from "../../../services/models/AlertType";
import CustomBreadcrumbs from "../../../components/BreadcrumbsComponent/CustomBreadcrumbs";

// SCHEMA FOR OPTION
interface selectOption {
  label: string;
  value: string;
}

// SCHEMA FOR FORM
interface formInitialValueTypes {
  investigationSampleCollectedFromId: number;
  consumerId: number;
  orgId: number;
  branchId: number;
  code: string;
  investigationSampleCollectedFromName: string;
  isDeleted: boolean;
}

// DEFAULT SCHEMA VALUE FOR FORM
const initInvestigationSampleCollectedFromData: formInitialValueTypes = {
  investigationSampleCollectedFromId: 0,
  consumerId: 0,
  orgId: 0,
  branchId: 0,
  code: "",
  investigationSampleCollectedFromName: "",
  isDeleted: false,
};

export default function InvestigationSampleCollectedFromSave() {
  // HOOKS AND CONTEXT
  const navigate = useNavigate();
  const edService = new EncryptDecryptService();
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }

  // GETTTING PARAMS
  let urlParams = useQuery();

  let curRawInvestigationSampleCollectedFromId = urlParams.get("q");
  let curInvestigationSampleCollectedFromId = 0;
  if (
    curRawInvestigationSampleCollectedFromId !== null &&
    curRawInvestigationSampleCollectedFromId !== undefined
  ) {
    let decId = edService.decrypt(curRawInvestigationSampleCollectedFromId);
    if (decId !== "" && decId !== null && decId !== undefined) {
      curInvestigationSampleCollectedFromId = +decId;
    }
  } else {
    if (urlParams.size > 0) {
      navigate("/not-found");
    }
  }

  // STATES FOR DATA STORAGE  -- PLEASE USE PROPER TYPES FOR STATE
  const [countryRegionData, setCountryRegionData] = useState<any[]>([]); // REPLACE ANY WITH COUNTRY INTERFACE
  const [countryData, setCountryData] = useState<any[]>([]); // REPLACE ANY WITH STATE INTERFACE

  // STATES -- FOR ERROR HANDLING AND LOADING
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showSkeleton, setShowSkeleton] = useState<boolean>(true);

  //SCHEMA VALIDATION
  const validationSchema = Yup.object().shape({
    investigationSampleCollectedFromId: Yup.number().nullable(),
    branchId: Yup.number().required(),
    orgId: Yup.number().required(),
    consumerId: Yup.number().required(),
    code: Yup.string(),
    investigationSampleCollectedFromName: Yup.string().required(
      "Sample Collected From Name is required"
    ),
    isDeleted: Yup.boolean(),
  });

  // FORM HOOK
  const methods = useForm({
    resolver: yupResolver(validationSchema), // IGNORE THIS ERROR
    defaultValues: initInvestigationSampleCollectedFromData, // DEFAULT VALUE -- DON'T CREATE STATE FOR IT -- STATE CAN INCREASE SITE LOAD
  });

  // GET InvestigationSampleCollectedFrom DETAIL
  const getInvestigationSampleCollectedFromDetailById = async () => {
    let lInvestigationSampleCollectedFrom: any;
    let params = new URLSearchParams();

    params.append(
      "investigationSampleCollectedFromId",
      curInvestigationSampleCollectedFromId?.toString()
    );
    params.append("branchId", userContext?.branchId?.toString());
    params.append("organizationId", userContext?.organizationId?.toString());
    params.append("consumerId", userContext?.consumerId?.toString());

    try {
      const res: any = await GetInvestigationSampleCollectedFromDetail(params);
      lInvestigationSampleCollectedFrom = res.data.data;
      methods.reset({
        ...initInvestigationSampleCollectedFromData,
        investigationSampleCollectedFromId:
          lInvestigationSampleCollectedFrom?.investigationSampleCollectedFromId ||
          0,
        code: lInvestigationSampleCollectedFrom?.code || "",
        investigationSampleCollectedFromName:
          lInvestigationSampleCollectedFrom?.investigationSampleCollectedFromName ||
          "",
      });
      return lInvestigationSampleCollectedFrom;
    } catch (e) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }
  };

  // SAVE AND UPDATE BRANCH
  const saveInvestigationSampleCollectedFrom = async (
    value: formInitialValueTypes
  ) => {
    try {
      let result: any = "";
      let formData = {
        investigationSampleCollectedFromId:
          value.investigationSampleCollectedFromId,
        branchId: userContext?.branchId,
        organizationId: userContext?.organizationId,
        consumerId: userContext?.consumerId,
        code: value.code?.trim(),
        investigationSampleCollectedFromName:
          value.investigationSampleCollectedFromName?.trim(),
        DataManagedBy: userContext.loginDetail.userId,
      };
      if (curInvestigationSampleCollectedFromId > 0) {
        result = await UpdateInvestigationSampleCollectedFrom(formData);
      } else {
        result = await CreateInvestigationSampleCollectedFrom(formData);
      }
      if (result?.data) {
        navigate(-1);
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: result?.message,
          alertType: AlertType.Success,
        });
      } else {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: "Something went wrong.",
          alertType: AlertType.Error,
        });
      }
    } catch (e) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }
  };

  // ON SUBMIT OF FORM
  const onSubmit = async (data: any) => {
    saveInvestigationSampleCollectedFrom(data);
  };

  // HANDLE BACK
  const handleBack = () => {
    navigate(-1);
  };

  // USEEFFECTS
  useEffect(() => {
    setIsLoading(false);
    if (curInvestigationSampleCollectedFromId > 0) {
      getInvestigationSampleCollectedFromDetailById();
    }
  }, [curInvestigationSampleCollectedFromId]);

  // HANDLE DELAY FOR SKELETON
  useEffect(() => {
    if (!isLoading) {
      const timeout = setTimeout(() => setShowSkeleton(false), 50); // Delay unmounting the Skeleton
      return () => clearTimeout(timeout);
    } else {
      setShowSkeleton(true);
    }
  }, [isLoading]);

  return (
    <Box>
      <Box className="main-container" pl={0}>
        <Grid container className="page-header">
          <Grid item xs={6} sm={6} md={6}>
            <Box
              sx={{
                display: "flex",
                height: "100%",
                alignItems: "center",
              }}>
              <CustomBreadcrumbs />
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Box className="page-header-icon">
              <Button
                color="primary"
                variant="outlined"
                type="button"
                className="secondary-button"
                startIcon={<ArrowBackIcon />}
                onClick={handleBack}>
                Back
              </Button>
            </Box>
          </Grid>
        </Grid>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} action="">
            <Grid container gap={2}>
              <Grid item xs={12} className="group-container">
                <Grid container item spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Basic Information</Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                      <FormLabel
                        className="fileds-label-text">
                        Code
                      </FormLabel>
                      <SkeletonWrapper
                        isLoading={showSkeleton}
                        className="skeleton-input">
                        <CustomTextField
                          placeholder=""
                          autoCapitalize="none"
                          type="text"
                          fullWidth
                          size="small"
                          {...methods.register(
                            "code",
                            {
                              required: true,
                              onChange: (e) => {
                                // CONVERT INPUT INTO UPPERCASE LATTERS
                                e.target.value = e.target.value
                                  ? e.target.value
                                  : "";
                                methods.setValue(
                                  "code",
                                  e.target.value,
                                  {
                                    shouldValidate: true,
                                  }
                                ); // UPDATE VALUE
                              },
                            }
                          )}
                          className={`text-input ${methods.formState.errors
                              .code
                              ? "error"
                              : ""
                            }`}
                        />
                      </SkeletonWrapper>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <FormLabel
                        className={`fileds-label-text  ${methods.formState.errors
                            .investigationSampleCollectedFromName
                            ? "validation-message"
                            : ""
                          }`}>
                        Sample Collected From *
                      </FormLabel>
                      <SkeletonWrapper
                        isLoading={showSkeleton}
                        className="skeleton-input">
                        <CustomTextField
                          placeholder=" Ex. Artery, Vein"
                          autoCapitalize="none"
                          type="text"
                          fullWidth
                          size="small"
                          {...methods.register(
                            "investigationSampleCollectedFromName",
                            {
                              required: true,
                              onChange: (e) => {
                                // CONVERT INPUT INTO UPPERCASE LATTERS
                                e.target.value = e.target.value
                                  ? e.target.value
                                  : "";
                                methods.setValue(
                                  "investigationSampleCollectedFromName",
                                  e.target.value,
                                  {
                                    shouldValidate: true,
                                  }
                                ); // UPDATE VALUE
                              },
                            }
                          )}
                          error={
                            !!methods.formState.errors
                              .investigationSampleCollectedFromName
                          }
                          className={`text-input ${methods.formState.errors
                              .investigationSampleCollectedFromName
                              ? "error"
                              : ""
                            }`}
                        />
                      </SkeletonWrapper>
                    </FormControl>
                    <Typography className="validation-message">
                      {
                        methods.formState.errors
                          .investigationSampleCollectedFromName?.message
                      }
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
                  <LoadingButton
                    color="primary"
                    loadingPosition="start"
                    startIcon={<></>}
                    variant="contained"
                    type="submit"
                    className="primary-button"
                    name="save"
                    disabled={showSkeleton || isLoading}>
                    {curInvestigationSampleCollectedFromId > 0
                      ? "Update"
                      : "Save"}
                  </LoadingButton>
                  <LoadingButton
                    color="primary"
                    loadingPosition="start"
                    startIcon={<></>}
                    variant="outlined"
                    type="button"
                    className="secondary-button"
                    name="cancel"
                    onClick={handleBack}>
                    Cancel
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </Box>
  );
}
