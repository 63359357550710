export class Context {
  isThemeDark: boolean = false;

  consumerId: number;
  consumerName: string = "";

  organizationId: number;
  organizationName: string = "";

  branchId: number;
  branchName: string = "";

  departmentId: number;
  departmentName: string = "";

  specialityId: number;
  specialityName: string = "";

  loginDetail: LoginDetail = new LoginDetail();

  isAlert: boolean = false;
  alertMessage: string = "";
  alertType: string = "success";

  maxOrganizations: number;
  isMenuVisible: boolean = false;

  subscriptionId: number;

  financialYearDetail: FinancialYearDetail = new FinancialYearDetail();
  // spouseFamilyMemberId: number = 0;
  // currFamilyMemberId: number = 0;

  // memberDetail: MemberDetail = new MemberDetail();
  // moduleConfiguration: any = [];

  // mobileNumber: string = "";
  // stage: string = "";
  // expectingChildNo: number = 0;
  // curMemberSelected: string = "";
  // curMaternalDetailId: number = -1;
  // isGoToMaternalDetail: boolean = false;
  // isComeFromStageSelection: boolean = false;
  // moduleConfigurationLoading: boolean = false;
  // memberDetailLoading: boolean = false;
  // isRegistrationComplete: boolean = false;
  // isAddMember: boolean = false;
  // isAddMemberFromList: boolean = false;
  // isEditMemberFromBottomSheet: boolean = false;
  // isEditMaternalDetail: boolean = false;
  // isAddMaternalDetail: boolean = false;
  // isEditMemberFromList: boolean = false;
  // isBabyDelivered: boolean = false;
  // isAddChildDetail: boolean = false;
  // isEdit: boolean = false
  // isMotherExist: boolean = false;
  // isUnauthorized: boolean = false;
  // isWifePregnant: boolean = false;

  // dateOfDelivery: string = "";
  // symptomsCategoryCode: string = "";
  // symptomsCategoryName: string = "";
  // symptomsCode: string = "";
  // symptomsName: string = "";
}

export class LoginDetail {
  token: string = "";
  tokenExpiryTime: string = "";
  userId: number = 0;
  userKey: string = "";
  userRole: any = "";
  loginClick: boolean = true;
  prefix: string = "";
  firstName: string = "";
  lastName: string = "";
  fullName: string = "";
  email: string = "";
  countryID: number = 91;
  isAuthorized: boolean = true;
}

export class MemberDetail {
  age: number = 0;
  birthDate: string = "";
  familyId: number = 0;
  familyMemberId: number = 0;
  familyRelationName: string = "";
  firstName: string = "";
  lastName: string = "";
  gender: string = "";
  isPrematureChild: boolean = false;
  isSpecialChild: boolean = false;
  profileImageBase: string = "";
  curMemberSelectedLanguage: string = "";
  maternalDetailId: number = 0;
  complicationsDetails: string = "";
  expectingChildNo: number = 0;
  expectingDateOfDelivery: string = "";
  gestationalAge: number = 0;
  gestationalAgeTime: string = "";
  isChildBorn: boolean = false;
  isTermination: boolean = false;
  isPragnentExpectingChild: boolean = false;
  lastManstrualPeriodDate: string = "";
  notificationCount: number = 0;
}

export class FinancialYearDetail {
  financialYear: string = "";
  financialYearStartDate: Date;
  financialYearEndDate: Date;
}
