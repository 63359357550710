import { styled, TextField } from "@mui/material";

export const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#FAFBFD", // Set the desired background color
    borderRadius: '8px !important', 
    "& fieldset": {
        borderColor: theme.palette.grey[300],
        borderWidth: "1px",
        BorderStyle: "solid",
        borderRadius:'8px !important',
      },
      "&:hover fieldset": {
        borderColor: theme.palette.grey[500],
        borderWidth: "1px",
        BorderStyle: "solid",
        borderRadius:'8px !important',
      },
      "&.Mui-focused fieldset": {
        borderWidth: "2px",
        borderColor: theme.palette.primary.main,
        borderRadius:'8px !important',
      },
    },
  }));