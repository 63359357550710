import { GET, POST } from "./common/http";
import { BASE_URL } from "./common/const";

export const GetOrganizationDetail = (params: any) =>
  GET(`${BASE_URL}/api/Organization/GetDetail?` + params, null);

export const GetOrganizationList = (params: any) =>
  GET(`${BASE_URL}/api/Organization/GetList?` + params, null);

export const CreateOrganization = (data: any) =>
  POST(`${BASE_URL}/api/Organization/Create`, data, true);

export const UpdateOrganization = (data: any) =>
  POST(`${BASE_URL}/api/Organization/Update`, data, true);

export const DeleteOrganization = (data: any) =>
  POST(`${BASE_URL}/api/Organization/Delete`, data);






